// 公用api
import request from '@/tools/request';

// 商品例表
export function goodslist(data, loading) {
  return request({
    url: `/pricecutsremind-goods-list.html`,
    method: 'get',
    params: data,
    loading,
  });
}
// 手动重发邮件
export function sendemailhandle(data) {
  return request({
    url: `/pricecutsremind-task-sendemailhandle.html`,
    method: 'get',
    params: data,
  });
}
