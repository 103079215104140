<template>
  <div>
    <header>
      <m-page-header title="通知商品列表"></m-page-header>
    </header>
    <main class="main">
      <div class="main_panel">
        <formSkeleton type="mini-text" :loading="loading && Isstatis">
          <div class="grid-content bg-purple">
            <div class="main_svg">
              <span>
                <i class="iconfont icon-newform-shopping"></i>
              </span>
            </div>
            <div>
              <p class="number">
                <strong>{{ statis.goods_count }}</strong>
              </p>
              <p>总登记降价商品（件）</p>
            </div>
          </div>
        </formSkeleton>
        <formSkeleton type="mini-text" :loading="loading && Isstatis">
          <div class="grid-content bg-purple">
            <div class="main_svg">
              <span>
                <i class="iconfont icon-newform-send"></i>
              </span>
            </div>
            <div>
              <p class="number">
                <strong>{{ statis.send_count }}</strong>
              </p>
              <p>已发送邮件</p>
            </div>
          </div>
        </formSkeleton>
        <formSkeleton type="mini-text" :loading="loading && Isstatis">
          <div class="grid-content bg-purple">
            <div class="main_svg">
              <span>
                <i class="iconfont icon-newform-youjian"></i>
              </span>
            </div>
            <div>
              <p class="number">
                <strong>
                  {{ statis.open_count }}
                </strong>
              </p>
              <p>已打开邮件</p>
            </div>
          </div>
        </formSkeleton>
        <formSkeleton type="mini-text" :loading="loading && Isstatis">
          <div class="grid-content bg-purple">
            <div class="main_svg">
              <span>
                <i class="iconfont icon-newform-yinhangka"></i>
              </span>
            </div>
            <div>
              <p class="number">
                <strong> {{ statis.order_count }}</strong>
              </p>
              <p>已付款</p>
            </div>
          </div>
        </formSkeleton>
        <formSkeleton type="mini-text" :loading="loading && Isstatis">
          <div class="grid-content bg-purple">
            <div class="main_svg">
              <span>
                <i class="iconfont icon-newform-dollar-circle-fill"></i>
              </span>
            </div>
            <div>
              <p class="number">
                <span class="huobi">{{ currency.symbol_left + ' ' }}</span>
                <span>{{ statis.order_amount_sum }}</span>
                <span class="huobi">{{ ' ' + currency.symbol_right }}</span>
              </p>
              <p>总金额</p>
            </div>
          </div>
        </formSkeleton>
      </div>
    </main>
    <div class="line"></div>
    <div class="main">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-input
              placeholder="请输入商品编号"
              v-model="pages.keyword"
              clearable
              size="medium"
              class="input-with-select"
            >
              <template #append>
                <el-button icon="el-icon-search" size="medium" @click="getDataList"></el-button>
              </template>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <div class="list-wrap">
        <m-table
          :data="dataList"
          :loading="loading"
          :pagination="{
            total: pages.total,
            pageSize: pages.page_size,
            currentPage: pages.page,
          }"
          @currentChange="handleCurrentChange"
        >
          <template #empty>
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column label="商品" width="350">
            <template #default="tableItem">
              <div class="good_list">
                <div class="shoppy_img">
                  <a :href="tableItem.row.goods_url" target="_blank">
                    <el-image
                      style="width: 70px; max-height: 70px; border-radius: 5px"
                      :src="tableItem.row.goods_img"
                      fit="fill"
                    >
                      <template #error>
                        <div class="image-slot">
                          <img src="../../../assets/img/goodserro.png" alt="" />
                        </div>
                      </template>
                    </el-image>
                  </a>
                </div>
                <div>
                  <a
                    :href="tableItem.row.goods_url"
                    class="base_name"
                    target="_blank"
                    :title="tableItem.row.goods_name"
                  >
                    {{ tableItem.row.goods_name }}
                  </a>
                  <p
                    class="number"
                    v-if="tableItem.row.goods_sku_value"
                    :title="tableItem.row.goods_sku_value"
                  >
                    {{ tableItem.row.goods_sku_value }}
                  </p>
                  <p class="number" v-else>—</p>
                  <p class="number">{{ tableItem.row.goods_codeno }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="邮箱">
            <template #default="tableItem">
              <p class="m-omit" :title="tableItem.row.user_email">{{ tableItem.row.user_email }}</p>
            </template>
          </el-table-column>
          <el-table-column label="登记时间" width="130">
            <template #default="tableItem">
              <p>{{ tableItem.row.create_time_YMD }}</p>
              <p>{{ tableItem.row.create_time_HMS }}</p>
            </template>
          </el-table-column>
          <el-table-column label="邮件发送状态" width="200">
            <template #default="tableItem">
              <el-tag v-if="tableItem.row.email_send === '3'">已发送</el-tag>
              <div v-else>
                <el-tag type="danger"> 未发送 </el-tag>
                <el-tooltip
                  v-if="tableItem.row.email_send_err_msg"
                  :content="tableItem.row.email_send_err_msg"
                  placement="right"
                  effect="light"
                  style="margin-left: 5px"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                <p class="resive_send" v-if="tableItem.row.email_send_err_msg">
                  <el-button
                    type="text"
                    :loading="tableItem.row.resiveSend"
                    @click="reload(tableItem.row)"
                    >重新发送</el-button
                  >
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="邮件打开时间" width="200">
            <template #default="tableItem">
              <div v-if="tableItem.row.email_open_time">
                <p>{{ tableItem.row.email_open_time_YMD }}</p>
                <p>{{ tableItem.row.email_open_time_HMS }}</p>
              </div>
              <div v-else>—</div>
            </template>
          </el-table-column>
          <el-table-column label="购买状态">
            <template #default="tableItem">
              <div v-if="tableItem.row.purchase_order_no">
                <strong
                  style="font-weight: bold"
                  class="m-omit"
                  :title="
                    currency.symbol_left +
                    tableItem.row.purchase_price +
                    currency.symbol_right +
                    '/' +
                    tableItem.row.purchase_num +
                    '件'
                  "
                  >{{ currency.symbol_left + ' ' }}{{ tableItem.row.purchase_price }}
                  {{ ' ' + currency.symbol_right }} /
                  {{ tableItem.row.purchase_num + '件' }}</strong
                >
              </div>
              <div v-else><el-tag type="danger">未购买</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="订单号">
            <template #default="tableItem">
              <span
                v-if="tableItem.row.purchase_order_no"
                class="m-omit"
                :title="tableItem.row.purchase_order_no"
                >{{ tableItem.row.purchase_order_no }}</span
              >
              <span v-else>—</span>
            </template>
          </el-table-column>
        </m-table>
      </div>
    </div>
  </div>
</template>

<script>
import formSkeleton from '@/components/formSkeleton/FormSkeleton';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { goodslist, sendemailhandle } from '../api/InfoShoppingList';
export default {
  name: 'InfoShoppingList',
  components: {
    formSkeleton,
  },
  setup() {
    // 初始化 列表数据
    const dataList = ref([]);
    const loading = ref(true);
    const statis = ref('');
    const currency = ref('');
    const Isstatis = ref(true);
    let pages = ref({
      page: 1,
      page_size: 10,
      keyword: '',
      total: 0,
    });
    async function getDataList() {
      const parms = {
        page: pages.value.page,
        page_size: pages.value.page_size,
        keyword: pages.value.keyword,
      };
      const { data } = await goodslist(parms, loading);
      data.list.map(res => {
        //res.goods_sku_value = res.goods_sku_value.replace(new RegExp('<br />', 'g'), '/');
        res.create_time_YMD = res.create_time.split(' ')[0];
        res.create_time_HMS = res.create_time.split(' ')[1];
        if (res.email_open_time) {
          res.email_open_time_YMD = res.email_open_time.split(' ')[0];
          res.email_open_time_HMS = res.email_open_time.split(' ')[1];
        }
        res.resiveSend = false;
      });
      dataList.value = data.list;
      Isstatis.value ? (statis.value = data.statis) : '';
      currency.value = data.currency;
      pages.value.total = data.pages.total;
      Isstatis.value = false;
    }
    const reload = async row => {
      //重新发送
      row.resiveSend = true;
      await sendemailhandle({ id: row.id, manual: 1 });
      ElMessage.success({
        message: '邮件发送成功!',
        type: 'success',
      });
      row.resiveSend = false;
      getDataList();
    };
    function handleCurrentChange(val) {
      pages.value.page = val;
      getDataList();
    }

    return {
      dataList,
      loading,
      statis,
      Isstatis,
      currency,
      getDataList,
      reload,
      handleCurrentChange,
      pages,
    };
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style scoped lang="scss">
@import '../statics/scss/iconfont';
.main {
  padding: 15px 20px;
  .main_time {
    span {
      padding-right: 15px;
    }
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: #409eff;
  }
  .list-wrap {
    margin-top: 15px;
    .resive_send button {
      color: #409eff;
      padding-left: 10px;
      cursor: pointer;
    }
    .resive_send button:hover {
      text-decoration: underline;
    }
    .good_list {
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        flex: 1;
        p {
          color: #000;
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .base_name {
          overflow: hidden;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .number {
          padding: 5px 0 3px 0;
          box-sizing: border-box;
        }
      }
      .shoppy_img {
        flex: 0 0 80px;
      }
    }
  }
  .main_panel {
    padding: 18px 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    > div {
      flex: 1;
    }
    .grid-content {
      width: 95%;
      margin: auto;
      height: 144px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: solid 1px #dedede;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      > div {
        flex: 1;
      }
      .main_svg {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f3;
          border-radius: 100%;
          i {
            font-size: 36px;
          }
        }
      }

      .number {
        font-size: 36px;
        color: #333333;
        padding-bottom: 10px;
        .huobi {
          font-size: 20px;
        }
      }
    }
  }
}

.line {
  width: 100%;
  height: 10px;
  background-color: #f9f9f9;
}
.el-form-item {
  margin: 0;
}
::v-deep(.el-form-item__label) {
  line-height: 25px;
}
@media screen and (max-width: 1280px) {
  .main .main_panel .grid-content .main_svg span {
    width: 40px;
    height: 40px;
  }
  .main .main_panel .grid-content .main_svg span i {
    font-size: 20px;
  }
  .main .main_panel .grid-content {
    > div {
      flex: 0 0 100%;
      text-align: center;
    }
  }
  .main .main_panel .grid-content .number {
    font-size: 20px;
  }
  .main .main_panel .grid-content .number .huobi {
    font-size: 16px;
  }
}
</style>
